import {
  Button as DefaultButton,
  ErrorMsg,
  Modal,
  SmallLoader,
} from "@dolthub/react-components";
import { useSignoutMutation } from "@gen/graphql-types";
import useApolloError from "@hooks/useApolloError";
import { handleCaughtError } from "@lib/errors/helpers";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import css from "./index.module.css";

type Props = {
  deviceDataCy: string;
  className?: string;
  pill?: boolean;
};

export default function SignOutButton({ className, ...props }: Props) {
  const router = useRouter();
  const [_signout, res] = useSignoutMutation();
  const [err, setErr] = useApolloError(undefined);
  const [signOutInProg, setSignOutInProg] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  useEffect(() => {
    if (err) {
      setErrorModalOpen(true);
      setSignOutInProg(false);
    }
  }, [err, setErr, setSignOutInProg]);

  const signout = async () => {
    setSignOutInProg(true);
    try {
      await _signout();
      await res.client.clearStore();
      router.push("/").catch(console.error);
    } catch (er) {
      handleCaughtError(er, setErr);
    }
  };

  const onClose = () => {
    setErrorModalOpen(false);
    setErr(undefined);
  };

  return (
    <div className={className} aria-label="sign-out-container">
      <Button
        deviceDataCy={props.deviceDataCy}
        pill={props.pill}
        signout={signout}
        signOutInProg={signOutInProg}
        hasError={!!err}
      />
      <Modal
        title="Error signing out"
        isOpen={errorModalOpen}
        onRequestClose={onClose}
      >
        <div aria-label="signout error modal">
          <ErrorMsg err={err} />
        </div>
      </Modal>
    </div>
  );
}

type ButtonProps = Props & {
  signout: () => Promise<void>;
  signOutInProg: boolean;
  hasError: boolean;
};

function Button(props: ButtonProps) {
  const buttonProps = {
    onClick: props.signout,
    "data-cy": `sign-out-button-${props.deviceDataCy}`,
  };
  if (props.pill) {
    return (
      <DefaultButton {...buttonProps} pill className={css.pill}>
        {props.signOutInProg ? "Signing out..." : "Sign Out"}
      </DefaultButton>
    );
  }
  if (props.signOutInProg) return <SignOutLoader hasError={props.hasError} />;
  return <DefaultButton.Link {...buttonProps}>Sign Out</DefaultButton.Link>;
}

type LoaderProps = {
  hasError: boolean;
};

function SignOutLoader({ hasError }: LoaderProps) {
  if (hasError) {
    return <div>Please try again.</div>;
  }
  return (
    <div aria-label="sign-out-loader">
      <SmallLoader.WithText
        text="Signing out..."
        loaded={false}
        outerClassName={css.loading}
      />
    </div>
  );
}
